/* The encoding is super important here to enable frame-by-frame scrubbing. */

// ffmpeg -i ~/Downloads/Toshiba\ video/original.mov -movflags faststart -vcodec libx264 -crf 23 -g 1 -pix_fmt yuv420p output.mp4
// ffmpeg -i ~/Downloads/Toshiba\ video/original.mov -vf scale=960:-1 -movflags faststart -vcodec libx264 -crf 20 -g 1 -pix_fmt yuv420p output_960.mp4

const video = document.querySelector(".video-background");
const map = [0, 8, 16, 20, 30];
let interval;
let sources;
let currentStep = 0;

function isVertical() {
  return window.screen.width < window.screen.height;
}

if (isVertical()) {
  sources = document.querySelectorAll("source.mobile");
  sources.forEach((el) => {
    el.setAttribute("src", el.dataset.src);
  });
  video.load();
} else {
  sources = document.querySelectorAll("source.desktop");
  sources.forEach((el) => {
    el.setAttribute("src", el.dataset.src);
  });
  video.load();
}

function windFwd(step) {
  if (video.currentTime > map[step]) {
    video.pause();
    document.querySelector(".video").classList = `video step-${step}`;
    document.querySelector(".video .box").style.display = "block";
    clearInterval(interval);
  }
}

function scrubTo(step) {
  clearInterval(interval);
  document.querySelector(".video").classList = `video`;
  document.querySelector(".video .cta").dataset.step = step;
  document.querySelector(".video .box").style.display = "none";
  video.playbackRate = 9.0;
  video.play();
  interval = setInterval(windFwd, 200, step);
}

function replay() {
  clearInterval(interval);
  document.querySelector(".video").classList = `video step-0`;
  document.querySelector(".video .cta").dataset.step = 0;
  document.querySelector(".video .box").style.display = "flex";
  video.currentTime = 0;
  currentStep = 0;
}

const cta = document.querySelector(".video .cta");
cta.addEventListener("click", (e) => {
  let step = parseInt(document.querySelector(".video .cta").dataset.step);

  if (step === 4) {
    return replay();
  }

  step++;
  currentStep++;
  scrubTo(step);
});

document.addEventListener("locationLoaded", function (e) {
  scrubTo(1);
});
